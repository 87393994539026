import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { INavbarData } from "./helper";
import { navbarData } from "./nav-data";
import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";

import { Router } from "@angular/router";

import { Subscription } from "rxjs";
import { SessionService } from "../session.service";
import { DataService } from "../data.service";

interface SideNavToggle {
  screenWidth: number;
  collapsed: boolean;
}
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
  animations: [
    trigger("faadeInOut", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate("350ms", style({ opacity: 1 })),
      ]),
      transition(":leave", [
        style({ opacity: 1 }),
        animate("350ms", style({ opacity: 0 })),
      ]),
    ]),
    trigger("rotate", [
      transition(":enter", [
        animate(
          "1000ms",
          keyframes([
            style({ transform: "rotate(0deg)", offset: "0" }),
            style({ transform: "rotate(2turn)", offset: "1" }),
          ])
        ),
      ]),
    ]),
    trigger("submenu", [
      state(
        "hidden",
        style({
          height: "0",
          overflow: "hidden",
        })
      ),
      state(
        "visible",
        style({
          height: "*",
        })
      ),
      transition("visible <=> hidden", [
        style({ overflow: "hidden" }),
        animate("{{transitionParams}}"),
      ]),
      transition("void => *", animate(0)),
    ]),
  ],
})
export class SidebarComponent implements OnInit, OnDestroy , AfterViewInit {
  @Input() collapsed: boolean=true;
  @Output() onToggleSideNav: EventEmitter<SideNavToggle> = new EventEmitter();
  @ViewChildren('textElement') textElements!: QueryList<ElementRef>;
  screenWidth: number = 0;
  navData = [];
  multiple: boolean = false;
  expandedList: any = [];
  subscribeSidebarbehaviour: Subscription;
  userDetails : any;
  constructor(
    private sessionService: SessionService,
    private router: Router,
    private dataService: DataService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.userDetails=this.sessionService.SessionService_GetUserDetails();
    const URL = this.router.url;
    this.drawSideBar(URL);
   this.dataService.goToDashboard.subscribe((res) => {
     if(res){
      this.recursiveFunction(this.navData,true)
      this.cdr.detectChanges();
     }
   })
  }

  ngAfterViewInit() {
    this.updateTooltips();
    this.textElements.changes.subscribe(() => {
      setTimeout(() => this.updateTooltips());
    });
  }
  updateTooltips() {
    this.textElements.forEach((elementRef: ElementRef) => {
      const element = elementRef.nativeElement;
      element.showTooltip = this.isTextOverflowing(element);
    });
  }

  drawSideBar(URL) {
    let drawSideBarItems: any = [];
    drawSideBarItems = JSON.parse(JSON.stringify(navbarData));
    
    let oUserDetails = this.sessionService.SessionService_GetUserDetails();
    if (oUserDetails.role == 'user') {
      const index=drawSideBarItems.findIndex(item=> item.label === 'Admin');
      if (index != -1) drawSideBarItems.splice(index,1);
    }
    this.navData = drawSideBarItems;
    let oSelectedSideBarItem = JSON.parse(localStorage.getItem('SideBarSelectedItem'));
    if(oSelectedSideBarItem) {
      this.updateNavigationState(this.navData,oSelectedSideBarItem.path)
    }
    else{
      if (this.navData && this.navData.length > 0) {
        const firstItem = this.navData[1];
        this.recursiveFunction(this.navData); // Deactivate all items
      this.updateNavigationState(this.navData,URL)
      firstItem.isActive = true;
      this.router.navigate([URL]);
    }
    }
    this.cdr.detectChanges();
  }

  updateNavigationState(navItems, targetObjectPath) {
    for (const item of navItems) {
      if (item.path == targetObjectPath) {
        item.isActive = true;
        item.isOpen = true;
        if (!item.items)
          localStorage.setItem("SideBarSelectedItem", JSON.stringify(item));
        return true;
      } else if (item.items) {
        if (this.updateNavigationState(item.items, targetObjectPath)) {
          item.isOpen = true;
          return true;
        }
      }
    }
    return false;
  }

  async toggleSubItems(item: INavbarData) {
    // Deactivate all items
    await this.recursiveFunction(this.navData);
    
    // Toggle the open state of the item if it has sub-items
    if (item.items) {
      item.isOpen = !item.isOpen;
    }
    
    // Activate the selected item
    if (item.routerLink !== "") {
      item.isActive = true;
      if (!item.items) {
        localStorage.setItem("SideBarSelectedItem", JSON.stringify(item));
      }
    }
    this.cdr.detectChanges();
  }
  

  recursiveFunction(oIncomingArray: INavbarData[],check?:boolean) {
    oIncomingArray.forEach((item) => {
      item.isActive = false;
      if(check)item.isOpen=false // Deactivate all items
      if (item.items) {
        this.recursiveFunction(item.items); // Recursively deactivate sub-items
      }
    });
  }
  
  isActiveRoute(item: INavbarData): boolean {
    if (!item.routerLink) {
      return false;
    }
    return this.router.isActive(
      this.router.createUrlTree([item.routerLink]),
      false
    );
  }
  
  ngOnDestroy(): void {
    if (this.subscribeSidebarbehaviour)
      this.subscribeSidebarbehaviour.unsubscribe();
  }
  isTextOverflowing(element: HTMLElement): boolean {
    return element.offsetWidth < element.scrollWidth;
  }
  
 
  
}
