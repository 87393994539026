    <!------------------------------- Range Calender ----------------------->
        <input
          type="text"
          id="rangeCalendarCenter"
          class="form-control range-calender"
          readonly
          ngxDaterangepickerBootstrap
          [(ngModel)]="selectedRangeCalendarCenter"
          [drops]="dropsDown"
          [linkedCalendars]="false"
          [opens]="opensCenter"
          [locale]="locale"
          [ranges]="ranges"
          [showCustomRangeLabel]="true"
          [showCancel]="true"
          [showDropdowns]="true"
          [alwaysShowCalendars]="false"
          [showRangeLabelOnInput]="false"
          [keepCalendarOpeningWithRange]="false"
          [isTooltipDate]="isTooltipDate"
          [isCustomDate]="isCustomDate"
          [isInvalidDate]="isInvalidDate"
          (datesUpdated)="datesUpdatedRange($event)"
        />
