import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { SessionService } from '../shared/session.service';



const canActivate = (route: ActivatedRouteSnapshot,state: RouterStateSnapshot) => 
{
  const sessionService = inject(SessionService);
  const router = inject(Router);
    if(sessionService.SessionService_GetUserDetails().role != 'user')
    {
        return true;
    }
    else
    {
        router.navigate(['/rd-dashboard-leadership/percentage-occurred']);
        return false;
    }
};
export const UserRoleGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {return canActivate(route, state);}