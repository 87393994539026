import { Component, Input, OnInit } from '@angular/core';
import * as dayjs from 'dayjs';
import { DataService } from 'src/app/shared/data.service';
@Component({
  selector: 'app-patients-not-scheduled-report',
  templateUrl: './patients-not-scheduled-report.component.html',
  styleUrls: ['./patients-not-scheduled-report.component.scss']
})
export class PatientsNotScheduledReportComponent implements OnInit {
  @Input() columns
  @Input() componentName
  formattedEndDate1 = dayjs(new Date().toISOString().split('T')[0])
  formattedStartDate1= dayjs(new Date(new Date().setDate(new Date().getDate() - 6)).toISOString().split('T')[0])  
  formattedEndDate = new Date().toISOString().split('T')[0]
  formattedStartDate = new Date(new Date().setDate(new Date().getDate() - 6)).toISOString().split('T')[0]
  paginatedData: any[] = [];
  currentPage: number = 1;
  itemsPerPage: number = 100;
  totalRecords: number;
  totalPages: number;
  start: number = 1;
  end: number = 100;
  isInitialLoad: boolean = false; 
  providerdropdownList = [];
  selectedItems = [];
  providerselectedItems = [];
  filteredProvider: any[];
  selectedServiceTypes: string[] = []; 
  filteredServiceType: any = [];
  lifeTimeData: any[] = [];
  serviceTypedropdownList: any;
  providerList: any;
  selectedProviders: any;
  lastUpdateTime: string;
  loader:boolean = false;
  dropdownLoader: boolean[] = [true, true];
  filterNames:string[] = ['Service Type','Provider'];
  
  constructor(private dataService: DataService) { }
  ngOnInit(): void {
    if(this.componentName=='leadership'){
      this.getServiceType(false)
      this.getProviderPatient()
    }else {
      this.getServiceType(true)
    }

  }

  getPNSDashboardData() {
 
    let body;
    let apiCall;
  
    if (this.componentName === 'leadership') {
      body = {
        startDate: this.formattedStartDate + ' 00:00:00',
        endDate: this.formattedEndDate + ' 23:59:59',
        serviceType: this.selectedServiceTypes,
        provider: this.selectedProviders,         
        start: this.start.toString(),
        size: this.itemsPerPage.toString(),
      };
      apiCall = this.dataService.GetRDDashboardLeadershipPNS(body);
    } else {
      body = {
        startDate: this.formattedStartDate + ' 00:00:00',
        endDate: this.formattedEndDate + ' 23:59:59',
        serviceType: this.selectedServiceTypes,   
        start: this.start.toString(),
        size: this.itemsPerPage.toString(),
      };
      apiCall = this.dataService.GetRDDashboardPNS(body);
    }
  
    apiCall.subscribe({
      next: (result) => {
        this.loader = false;
        if (result.status === 'failed') {
          console.error('Failed to fetch PNS data:', result.message);
        } else {
          this.lifeTimeData = result?.data?.data || [];
          this.totalRecords = result?.data?.total || this.lifeTimeData.length;
          this.totalPages = Math.ceil(this.totalRecords / this.itemsPerPage);
          let timestamp = result?.data?.lastUpdateTime?.updatedAt;
          this.lastUpdateTime = timestamp ? new Date(timestamp).toLocaleString() : 'No updates available';
          this.updateEnd();
        }
      },
      error: (error) => {
        this.loader = false;
        console.error('Error fetching PNS dashboard data:', error);
      }
    });
  }

  getServiceType(check) {
    this.loader = true;
    this.dropdownLoader[2] = true;
    let body = {
      startDate: this.formattedStartDate + ' 00:00:00',
      endDate: this.formattedEndDate + ' 23:59:59',
    };
  
    this.dataService.GetServiceTypePatient(body).subscribe({
      next: (result) => {
        this.dropdownLoader[2] = false; 
        if (result.status === 'failed') {
          console.error('Failed to fetch service types:', result.message);
        } else {
          this.serviceTypedropdownList = result.data.map(item => ({ ...item, checked: true }));
          this.selectedServiceTypes = this.serviceTypedropdownList.map(item => item.name);
          if(check)this.getPNSDashboardData();
        }
      },
      error: (error) => {
        this.dropdownLoader[2] = false; 
        console.error('Error fetching service types:', error);
      }
    });
  }
  
  getProviderPatient() {
    this.loader = true;
    this.dropdownLoader[3] = true; 
    let body = {
      startDate: this.formattedStartDate + ' 00:00:00',
      endDate: this.formattedEndDate + ' 23:59:59',
    };
  
    this.dataService.GetProviderPatient(body).subscribe({
      next: (result) => {
        this.dropdownLoader[3] = false; 
        if (result.status === 'failed') {
          console.error('Failed to fetch provider data:', result.message);
        } else {
          this.providerList = result.data.map(item => ({ ...item, checked: true }));
          this.selectedProviders = this.providerList.map(item => item.name);
          this.getPNSDashboardData();
        }
      },
      error: (error) => {
        this.dropdownLoader[3] = false; 
        console.error('Error fetching provider data:', error);
      }
    });
  }
  

  downloadCSVForRDDashboardPNS() {
    let body: any;
    let apiCall;
  
    if (this.componentName === 'leadership') {
      body = {
        startDate: this.formattedStartDate + ' 00:00:00',
        endDate: this.formattedEndDate + ' 23:59:59',
        start: this.start.toString(),
        size: this.itemsPerPage.toString(),
        providers: this.providerselectedItems,
        serviceType: this.selectedServiceTypes,
      };
      apiCall = this.dataService.ExportRDDashboardLeadershipPNS(body);
    } else {
      body = {
        startDate: this.formattedStartDate + ' 00:00:00',
        endDate: this.formattedEndDate + ' 23:59:59',
        start: this.start.toString(),
        size: this.itemsPerPage.toString(),
        serviceType: this.selectedServiceTypes,
      };
      apiCall = this.dataService.ExportRDDashboardPNS(body);
    }
  
    apiCall.subscribe({
      next: (result) => {
        if (result && result.status === 'success') {
          const fileUrl = result?.data?.url;
  
          if (fileUrl) {
            const a = document.createElement('a');
            a.href = fileUrl;
            a.download = this.componentName === 'leadership' ? 'rdd_dashboard_leadership_pns.csv' : 'rdd_dashboard_pns.csv';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          } else {
            console.error('No URL provided for the CSV download');
          }
        } else {
          console.error('Failed to download CSV:', result?.message);
        }
      },
      error: (error) => {
        console.error('Error downloading CSV:', error);
      }
    });
  }
  
  onDateChange(event: any) {
    if (this.isInitialLoad) {
      this.formattedStartDate = event.startDate;
      this.formattedEndDate = event.endDate;
      this.start = 1;
      this.currentPage = 1;
      if (this.componentName === 'leadership') {
       this.getServiceType(true)
       this.getProviderPatient()
      }
      else
      {

        this.getServiceType(true)
      }
    }
    this.isInitialLoad = true;
  }
  
  
  updatePaginatedData() {
    this.start = (this.currentPage - 1) * this.itemsPerPage + 1;
    this.end = Math.min(this.start + this.itemsPerPage - 1, this.totalRecords);
    this.getPNSDashboardData()
  }
  
  onPageChange(newPage: number) {
    if (newPage >= 1 && newPage <= this.totalPages) {
      this.currentPage = newPage;
      this.updatePaginatedData(); 
    }
  }
  
  onPageSizeChange(newPageSize: number) {
    this.itemsPerPage = newPageSize;  
    this.currentPage = 1;             
    this.totalPages = Math.ceil(this.totalRecords / this.itemsPerPage);
    this.updatePaginatedData();  
  }
  updateEnd() {
    this.end = Math.min(this.currentPage * this.itemsPerPage, this.totalRecords);
  }
 
  onSelectedItemsChange(selectedItems: any[], check) {
    check ? this.selectedServiceTypes = selectedItems : this.selectedProviders = selectedItems;
    this.start = 1;
    this.currentPage = 1;
    this.updateEnd();
    this.getPNSDashboardData()
}

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  getColumnClass(columnName: string): string {
    if (columnName === 'Appt Date/Time') {
      return 'text-left';
    }  else {
      return 'text-left';
    }
  }
}
