import { INavbarData } from "./helper";

export const navbarData: INavbarData[] = [
  {
    routerLink: "",
    label: "Billing Dashboard",
    items: [
      {
        routerLink: "/billing-dashboard-reports/insurance-check",
        path: "/billing-dashboard-reports/insurance-check",
        label: "Insurance Check",
      },
      {
        routerLink: "/billing-dashboard-reports/encounters",
        path: "/billing-dashboard-reports/encounters",
        label: "Encounters",
      },
    ],
  },
  {
    routerLink: "",
    label: "RD Dashboard - Leadership",
    items: [
      {
        routerLink: "/rd-dashboard-leadership/appointment-units-overview",
        path: "/rd-dashboard-leadership/appointment-units-overview",
        label: "Appointment & Units Overview",
      },
      {
        routerLink: "/rd-dashboard-leadership/scheduled-high-level-view",
        path: "/rd-dashboard-leadership/scheduled-high-level-view",
        label: "Scheduled High Level View",
      },
      {
        routerLink: "/rd-dashboard-leadership/patients-not-scheduled",
        path: "/rd-dashboard-leadership/patients-not-scheduled",
        label: "Patients not Scheduled",
      },
      {
        routerLink: "/rd-dashboard-leadership/percentage-occurred",
        path: "/rd-dashboard-leadership/percentage-occurred",
        label: "Percentage Occurred",
      },
      {
        routerLink: "/rd-dashboard-leadership/cancellation-rate",
        path: "/rd-dashboard-leadership/cancellation-rate",
        label: "Cancellation Rate",
      },
      {
        routerLink: "/rd-dashboard-leadership/gdm-patients",
        path: "/rd-dashboard-leadership/gdm-patients",
        label: "GDM Patients",
      },
      {
        routerLink: "/rd-dashboard-leadership/gdm-vs-non-gdm-patient-view",
        path: "/rd-dashboard-leadership/gdm-vs-non-gdm-patient-view",
        label: "GDM vs. Non-GDM Patient View",
      },
      {
        routerLink: "/rd-dashboard-leadership/lifetime-units",
        path: "/rd-dashboard-leadership/lifetime-units",
        label: "Lifetime Units",
      },
      {
        routerLink: "/rd-dashboard-leadership/diabetes-clinical-time-by-rd",
        path: "/rd-dashboard-leadership/diabetes-clinical-time-by-rd",
        label: "Diabetes Clinical Time by RD",
      },
    ],
  },
  {
    routerLink: "",
    label: "Clinical Data Review",
    items: [
      {
        routerLink: "/clinical-data-review/quality-analysis",
        path: "/clinical-data-review/quality-analysis",
        label: "Quality Analysis",
      },
    ],
  },
  {
    routerLink: "",
    label: "Operations Analysis",
    items: [
      {
        routerLink: "/operations-analysis/appts-units-overview",
        path: "/operations-analysis/appts-units-overview",
        label: "Appts & Units Overview",
      },
      {
        routerLink: "/operations-analysis/scheduled-fu--weeks-out",
        path: "/operations-analysis/scheduled-fu--weeks-out",
        label: "Scheduled FU & Weeks Out",
      },
      {
        routerLink: "/operations-analysis/weekly-units-scheduled-initial",
        path: "/operations-analysis/weekly-units-scheduled-initial",
        label: "Weekly Units Scheduled Initial",
      },
      {
        routerLink: "/operations-analysis/appointments-by-status",
        path: "/operations-analysis/appointments-by-status",
        label: "Appointments by Status (2020)",
      },
      {
        routerLink: "/operations-analysis/weekly-occurred-by-rd",
        path: "/operations-analysis/weekly-occurred-by-rd",
        label: "Weekly Occurred % by RD",
      },
      {
        routerLink: "/operations-analysis/rolling-utilization",
        path: "/operations-analysis/rolling-utilization",
        label: "Rolling Utilization",
      },
      {
        routerLink: "/operations-analysis/normalized-utilization",
        path: "/operations-analysis/normalized-utilization",
        label: "Authorizations",
      },
    ],
  },
  {
    routerLink: "",
    label: "Metrics Compliance",
    items: [
      {
        routerLink: "/metrics-compliance/overview",
        path: "/metrics-compliance/overview",
        label: "Overview",
      },
      {
        routerLink: "/metrics-compliance/weight-compliance",
        path: "/metrics-compliance/weight-compliance",
        label: "Weight Compliance",
      },
      {
        routerLink: "/metrics-compliance/a1c-triglyceride-and-msq",
        path: "/metrics-compliance/a1c-triglyceride-and-msq",
        label: "A1c Triglyceride and MSQ",
      },
      {
        routerLink: "/metrics-compliance/cholesterol-hdl-ldl-total",
        path: "/metrics-compliance/cholesterol-hdl-ldl-total",
        label: "Cholesterol (HDL/LDL/Total)",
      },
    ],
  },
  {
    routerLink: "",
    label: "Billing & Ops Compliance",
    items: [
      {
        routerLink: "/simplex-billing-ops-compliance/no-show-report",
        path: "/simplex-billing-ops-compliance/no-show-report",
        label: "No Show Report",
      },
    ],
  },
  {
    routerLink: "",
    label: "RD Dashboard",
    items: [
      {
        routerLink:
          "/simplex-health-general-dashboard/appointment-units-overview",
        path: "/simplex-health-general-dashboard/appointment-units-overview",
        label: "Appointment & Units Overview",
      },
      {
        routerLink:
          "/simplex-health-general-dashboard/scheduled-high-level-view",
        path: "/simplex-health-general-dashboard/scheduled-high-level-view",
        label: "Scheduled High Level View",
      },
      {
        routerLink: "/simplex-health-general-dashboard/patients-not-scheduled",
        path: "/simplex-health-general-dashboard/patients-not-scheduled",
        label: "Patients not Scheduled",
      },
      {
        routerLink: "/simplex-health-general-dashboard/percentage-occurred",
        path: "/simplex-health-general-dashboard/percentage-occurred",
        label: "Percentage Occurred",
      },
      {
        routerLink: "/simplex-health-general-dashboard/cancellation-rate",
        path: "/simplex-health-general-dashboard/cancellation-rate",
        label: "Cancellation Rate",
      },
      {
        routerLink: "/simplex-health-general-dashboard/gdm-patients",
        path: "/simplex-health-general-dashboard/gdm-patients",
        label: "GDM Patients",
      },
      {
        routerLink: "/simplex-health-general-dashboard/diabetes-management",
        path: "/simplex-health-general-dashboard/diabetes-management",
        label: "Diabetes Management",
      },
      {
        routerLink: "/simplex-health-general-dashboard/lifetime-units",
        path: "/simplex-health-general-dashboard/lifetime-units",
        label: "Lifetime Units",
      },
    ],
  },
  {
    routerLink: "",
    label: "Tellescope",
    items: [
      {
        routerLink: "/tellescope/dashboard",
        path: "/tellescope/dashboard",
        label: "Dashboard",
      },
    ],
  },
  {
    routerLink: "/admin",
    path: "/admin",
    label: "Admin",
  },
];
