import { Component, Input, OnInit } from '@angular/core';
import * as dayjs from 'dayjs';
import { DataService } from 'src/app/shared/data.service';

@Component({
  selector: 'app-cancellation-rate-report',
  templateUrl: './cancellation-rate-report.component.html',
  styleUrls: ['./cancellation-rate-report.component.scss']
})
export class CancellationRateReportComponent {
  @Input() columns
  @Input() componentName
  formattedStartDate1 = dayjs(new Date().getFullYear()+'-01-01')
  formattedEndDate1 = dayjs();;  
  formattedStartDate = new Date().getFullYear()+'-01-01'
  formattedEndDate = new Date().toISOString().split('T')[0]; 
  paginatedData: any[] = [];
  currentPage: number = 1;
  itemsPerPage: number = 100;
  totalRecords: number;
  totalPages: number;
  start: number = 1;
  end: number = 100;
  lifeTimeData: any[] = [];
  isInitialLoad: boolean = false; 
  selectedServiceTypes: string[] = []; 
  providers: any;
  selectedProviders: any;
  dropdownLoader: boolean = true;
  lastUpdateTime: string;
  loader: boolean = false;
  filterNames:string = 'Provider'

  constructor(private dataService: DataService) { }
  
  ngOnInit(): void {
    this.getCancelletionDashboardData()
  if(this.componentName=='leadership')
  {
    this.fetchProviders();
  
  }
  }


  getCancelletionDashboardData() {
    this.loader = true;
    let body;
    let apiCall;
    if (this.componentName === 'leadership') {
      body = {
        startDate: this.formattedStartDate + ' 00:00:00',
        endDate: this.formattedEndDate + ' 23:59:59',
        start: this.start.toString(),                                 
        size: this.itemsPerPage.toString(),
        providers: this.selectedProviders,
      };
      apiCall = this.dataService.GetRDDashboardLeadershipCR(body);
    } else {
      body = {
        startDate: this.formattedStartDate + ' 00:00:00',
        endDate: this.formattedEndDate + ' 23:59:59',
        start: this.start.toString(),                                 
        size: this.itemsPerPage.toString(),
      };
      apiCall = this.dataService.GetRDDashboardCR(body);
    }
  
    apiCall.subscribe({
      next: (result) => {
        this.loader = false;
        if (result.status === 'failed') {
          console.error('Failed to fetch billing data:', result.message);
        } else {
          this.lifeTimeData = result?.data?.data || [];
          this.totalRecords = result?.data?.total || this.lifeTimeData.length;
          this.totalPages = Math.ceil(this.totalRecords / this.itemsPerPage);
          let timestamp = result?.data?.lastUpdateTime?.updatedAt; 
          this.lastUpdateTime = timestamp ? new Date(timestamp).toLocaleString() : 'No updates available';          
          this.updateEnd();
        }
      },
      error: (error) => {
        this.loader = false;
        console.error('Error fetching billing dashboard data:', error);
      }
    });
  }

  downloadCSVForRDDashboardCR() {
    let body: any;
    let apiCall;
  
    // Choose the API call and body structure based on componentName
    if (this.componentName === 'leadership') {
      body = {
        startDate: this.formattedStartDate + ' 00:00:00',
        endDate: this.formattedEndDate + ' 23:59:59',
        start: this.start.toString(),
        size: this.itemsPerPage.toString(),
        providers: this.selectedProviders, // Assuming you want to use selectedProviders here
      };
      apiCall = this.dataService.ExportRDDashboardLeadershipCR(body);
    } else {
      body = {
        startDate: this.formattedStartDate + ' 00:00:00',
        endDate: this.formattedEndDate + ' 23:59:59',
        start: this.start.toString(),
        size: this.itemsPerPage.toString(),
      };
      apiCall = this.dataService.ExportRDDashboardCR(body);
    }
  
    apiCall.subscribe({
      next: (result) => {
        if (result && result.status === 'success') {
          const fileUrl = result?.data?.url;
  
          if (fileUrl) {
            const a = document.createElement('a');
            a.href = fileUrl;
            a.download = this.componentName === 'leadership' ? 'rdd_dashboard_leadership.csv' : 'rdd_dashboard.csv';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          } else {
            console.error('No URL provided for the CSV download');
          }
        } else {
          console.error('Failed to download CSV:', result?.message);
        }
      },
      error: (error) => {
        console.error('Error downloading CSV:', error);
      }
    });
  }
  

  fetchProviders(): void {
    let body = {
      startDate: this.formattedStartDate + ' 00:00:00',
      endDate: this.formattedEndDate + ' 23:59:59',
    };
  
    this.dataService.GetProviderCancellation(body).subscribe({
      next: (result) => {
        this.dropdownLoader = false;
        if (result.status === 'failed') {
          console.error('Failed to fetch provider data:', result.message);
        } else {
          this.providers = result.data.map(item => ({ ...item, checked: true }));
          this.selectedProviders = this.providers.map(item => item.name); 
        }
      },
      error: (error) => {
        this.dropdownLoader = false;
        console.error('Error fetching provider data:', error);
      }
    });
  }
  
  

  onDateChange(event: any) {
    if (this.isInitialLoad) {
      this.formattedStartDate = event.startDate;
      this.formattedEndDate = event.endDate;
      this.start = 1;
      this.currentPage = 1;
      if (this.componentName === 'leadership') {
        this.fetchProviders();
      }
      this.getCancelletionDashboardData();
    }
    this.isInitialLoad = true;
  }
  
  
  updatePaginatedData() {
    this.start = (this.currentPage - 1) * this.itemsPerPage + 1;
    this.end = Math.min(this.start + this.itemsPerPage - 1, this.totalRecords);
    this.getCancelletionDashboardData()
  }
  
  onPageChange(newPage: number) {
    if (newPage >= 1 && newPage <= this.totalPages) {
      this.currentPage = newPage;
      this.updatePaginatedData(); 
    }
  }
  
  onPageSizeChange(newPageSize: number) {
    this.itemsPerPage = newPageSize;  
    this.currentPage = 1;             
    this.totalPages = Math.ceil(this.totalRecords / this.itemsPerPage);
    this.updatePaginatedData();  
  }
  updateEnd() {
    this.end = Math.min(this.currentPage * this.itemsPerPage, this.totalRecords);
  }
 
  onSelectedItemsChange(selectedItems) {
 this.selectedProviders = selectedItems 
 this.start = 1;
 this.currentPage = 1;
 this.updateEnd();
 this.getCancelletionDashboardData()
  }

  getColumnClass(columnName: string): string {
    if (columnName === 'Year Week') {
      return 'text-left';
    } else if (columnName === 'Cancelled') {
      return ' text-left';
    } else {
      return 'text-left';
    }
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  
}
