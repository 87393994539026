import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { SessionService } from '../session.service';


@Component({
  selector: 'app-custom-pagination',
  templateUrl: './custom-pagination.component.html',
  styleUrls: ['./custom-pagination.component.scss']
})
export class CustomPaginationComponent implements OnInit{
  @Input() currentPage: number;
  @Input() totalPage: number;
  @Input() totalItems: number;
  @Input() numberOfItems: number;
  @Input() isPDM: boolean = false;
  @Input() start: number;
  @Output() pageChange = new EventEmitter<number>();
  @Output() pageSizeChange = new EventEmitter<number>();
  pageItems:any=[10,20,50]
  recordSize:number=10;
  constructor(private sessionService:SessionService){}
  ngOnInit(): void {    

    console.log(this.start,this.numberOfItems,this.totalItems);
    
    const pageSizeLocalStorage = this.sessionService.SessionService_GetPageSize();
    if (pageSizeLocalStorage) {
     this.recordSize=pageSizeLocalStorage
    }
    else {
      this.recordSize=10;
    }
  }
  goToPreviousPage() {
    if (this.currentPage > 1) {
        this.pageChange.emit(this.currentPage - 1);
    }
  }

  goToNextPage() {
    if (this.currentPage < this.totalPage) {
        this.pageChange.emit(this.currentPage + 1);
    }
  }

  onPageSizeChange(size: number) {
    this.sessionService.SessionService_SetPageSize(size)
    this.recordSize=size;
    this.pageSizeChange.emit(size);
  }
}
