
const env = window["env"] || {};

export const environment = {
  production: env["production"] || false,
  BaseURL: env["BaseURL"] || 'https://simplexhealth.trilloapps.com',
  AccessToken : env["AccessToken"] || 'lsSimplexAccessToken',
  Server: env["Server"] || 'staging',
  APP_NAME: env["APP_NAME"] || 'SimplexHealth',
  Title: env["Title"] || 'Simplex Health',
  branch: env["branch"] || 'development/1.0', 
};
