<div class="row pt-2 dropdown-area mb-3  mx-2">
    <!------------------------------- Range Calender ----------------------->

    <div class="col-lg-4 mb-2">
    <app-range-calender  (onDateChange)="onDateChange($event)" [defaultStartDate]="formattedStartDate1"
    [defaultEndDate]="formattedEndDate1"></app-range-calender>
    </div>

    <!------------------------------- Provider dropdown ----------------------->
    <div class="col-lg-4 mb-2">
      <div class="text-center">
        <app-skeleton-loader *ngIf="dropdownLoader[2]"></app-skeleton-loader>
      </div>
      <app-custom-dropdown
        *ngIf="!dropdownLoader[2]"
        [items]="serviceTypedropdownList"
        [filterName]="filterNames[0]"
        [maxDisplayLength]="20"
        (selectedItemsChange)="onSelectedItemsChange($event, true)"
      ></app-custom-dropdown>
    </div>
    
    <div *ngIf="componentName === 'leadership'" class="col-lg-4 mb-2">
      <div class="text-center">
        <app-skeleton-loader *ngIf="dropdownLoader[3]"></app-skeleton-loader>
      </div>
      <app-custom-dropdown
        *ngIf="!dropdownLoader[3]"
        [filterName]="filterNames[1]"
        [items]="providerList"
        [maxDisplayLength]="20"
        (selectedItemsChange)="onSelectedItemsChange($event, false)"
      ></app-custom-dropdown>
    </div>
    
  </div>
  <div class="d-flex justify-content-between align-items-center mb-2 report-section"> 
    <div>   
        <small class="text-muted font-10">Last Updated</small>
        <div>{{ lastUpdateTime }}</div>
    </div>
<div >
    <button
        [class.disabled]="lifeTimeData?.length == 0"
        class="fa-solid fa-cloud-download"
        ngbTooltip="Download CSV"
        (click)="downloadCSVForRDDashboardPNS()"
        placement="left"
        [disabled]="lifeTimeData?.length == 0"
    >
    </button>
</div>
</div>

<div class="table-container table-responsive position-relative">

  <div *ngIf="loader">
    <app-loader></app-loader>
  </div>

    <table>
        <thead>
          <tr>
            <th *ngFor="let header of columns"
                [ngClass]="getColumnClass(header.name)">
              {{ header.name }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of lifeTimeData">
            <td *ngFor="let header of columns" 
                [ngClass]="getColumnClass(header.name)">
              {{ row[header.value] !== null && row[header.value] !== undefined ? row[header.value] : '-' }}
            </td>
          </tr>
        </tbody>
      </table>      
    <div *ngIf="lifeTimeData?.length === 0 && !loader" class="text-center mt-2">
      No Data Found!
    </div>
  </div>
  <div class="me-3" >
    <app-custom-pagination *ngIf="totalRecords > 0"
    [currentPage]="currentPage"
    [totalPage]="totalPages"
    [totalItems]="totalRecords"
    [numberOfItems]="end"
    [isPDM]="true"
    [start]="start"
    (pageChange)="onPageChange($event)"
    (pageSizeChange)="onPageSizeChange($event)">
</app-custom-pagination>
</div>
