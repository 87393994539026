import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DataService {
    toggleCollapse: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    goToDashboard: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    constructor(private http: HttpClient) { }
    // GetProvider
    GetProvider(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetProvider', body);
    }
    //   GetServiceType
    GetServiceType(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetServiceType', body);
    }
    //   GetOperationsAnalysis
    GetOperationsAnalysis(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetOperationsAnalysis', body);
    }
    DowloadCSV(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DownloadCSV', body);
    }
    forgotPassword(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/_preauthsvc/user/forgotPassword', body)
    }
    ResetPasswordAuth(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/_preauthsvc/user/resetPassword', body)
    }

    //************* User Manager API starts */
    GetUsersList(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/page/auth/vault', body);
    }
    CreateUser(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/_service/um/newUser', body);
    }

    UploadUsers(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/authds/uploadUsers', body);
    }
    EditUser(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/_service/um/editUser', body);
    }
    ResetPassword(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/_service/um/resetPassword', body);
    }
    SuspendUser(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/_service/um/toggleSuspendActive', body);
    }
    DeleteUser(id: any): Observable<any> {
        return this.http.delete<any>(environment.BaseURL + '/_service/um/deleteUser' + "?id=" + id);
    }
    AddNewTenant(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/save/auth/vault/Org', body);
    }
    ChangeProfileImage(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/upload',body);
      }
    //************* User Manager API Ends */

    //Setting ======>> User Profile API's End
    GetUserProfile(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetUserProfile', body);
    }
    SetUserProfile(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/_service/um/editMyProfile', body);
    }
    ChangePassword(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/_service/um/changeMyPassword', body);
    }
    // RD Dashboard-leadership====> API'S End point
    diabetesClinicalTime(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetRDDashboardLeadershipDCT', body);
    }
    DowloadCSVDashboardLeadershipDCT(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportRDDashboardLeadershipDCT', body);
    }
    GetRDDasshboardLeadershipData(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetRDDasshboardLeadershipData', body);
    }
    ExportRDDasshboardLeadershipData(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportRDDasshboardLeadershipData', body);
    }
    GetAllProviders(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetAllProviders', body);
    }



     // Simplex Billing Dashboard====> API'S End point
    GetBillingDashboardData(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetBillingDashboardData', body);
    }
    GetBillingDashboardStatus(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetStatus', body);
    }
    GetBillingDashboardServiceType(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetServiceType', body);
    }
    DowloadCSVBillingDashboardEncounter(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportBillingDashboardEncounters', body);
    }
    GetBillingDashboardICTable1(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetBillingDashboardICTable1', body);
    }
    GetBillingDashboardICTable2(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetBillingDashboardICTable2', body);
    }
    GetBillingDashboardICTable3(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetBillingDashboardICTable3', body);
    }
    GetAppointmentType(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetAppointmentType', body);
    }
    GetPrimaryInsurance(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetPrimaryInsurance', body);
    }
    GetChiefComplaint(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetChiefComplaint', body);
    }
    GetClientGroup(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetClientGroup', body);
    }
    GetPayor(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetPayor', body);
    }
    GetClinicalTable1Data(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetClinicalTable1Data', body);
    }
    GetClinicalTable2Data(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetClinicalTable2Data', body);
    }
    DowloadCSVQualityAnalysis1(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportClinicalTable1Data', body);
    }
    DowloadCSVQualityAnalysis2(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportClinicalTable2Data', body);
    }
    ExportBillingDashboardICTable1(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportBillingDashboardICTable1', body);
    }
    ExportBillingDashboardICTable2(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportBillingDashboardICTable2', body);
    }
    ExportBillingDashboardICTable3(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportBillingDashboardICTable3', body);
    }
    ExportBillingDashboardEncounters(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportBillingDashboardEncounters', body);
    }

      // Bliing & ops compliance====> API'S End point
      GetSBOCNoShow(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetSBOCNoShow', body);
    }
    DownloadCSVGetSBOCNoShow(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DownloadCSVGetSBOCNoShow', body);
    }
}
