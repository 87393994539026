import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './header/header.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AlertsComponent } from './alerts/alerts.component';
import { LoaderComponent } from './loader/loader.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { CustomPaginationComponent } from './custom-pagination/custom-pagination.component';
import { HeadersInterceptor } from 'src/interceptors/headers.interceptor';
import { AppOutsideClickDirective } from './directives/app-outside-click.directive';
import { RangeCalenderComponent } from './range-calender/range-calender.component';
import { NgxDaterangepickerBootstrapModule, NgxDaterangepickerLocaleService } from 'ngx-daterangepicker-bootstrap';



@NgModule({
  declarations: [
    HeaderComponent,
    AlertsComponent,
    LoaderComponent,
    SidebarComponent,
    CustomPaginationComponent,
    AppOutsideClickDirective,
    RangeCalenderComponent,

  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NgbModule,
    RouterModule,
    NgxDaterangepickerBootstrapModule.forRoot(),
  ],
  providers: [
    HeadersInterceptor,
    NgxDaterangepickerLocaleService
  ],
  exports:[ HeaderComponent,AlertsComponent,LoaderComponent,SidebarComponent,CustomPaginationComponent,AppOutsideClickDirective,RangeCalenderComponent ]
})
export class SharedModule { }
