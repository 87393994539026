import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DataService {
    toggleCollapse: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    goToDashboard: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    constructor(private http: HttpClient) { }
    // GetProvider
    GetProvider(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetProvider', body);
    }
    //   GetServiceType
    GetServiceType(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetServiceType', body);
    }
    //   GetOperationsAnalysis
    GetOperationsAnalysis(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetOperationsAnalysis', body);
    }
    DowloadCSV(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DownloadCSV', body);
    }
    forgotPassword(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/_preauthsvc/user/forgotPassword', body)
    }
    ResetPasswordAuth(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/_preauthsvc/user/resetPassword', body)
    }

    //************* User Manager API starts */
    GetUsersList(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/page/auth/vault', body);
    }
    CreateUser(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/_service/um/newUser', body);
    }

    UploadUsers(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/authds/uploadUsers', body);
    }
    EditUser(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/_service/um/editUser', body);
    }
    ResetPassword(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/_service/um/resetPassword', body);
    }
    SuspendUser(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/_service/um/toggleSuspendActive', body);
    }
    DeleteUser(id: any): Observable<any> {
        return this.http.delete<any>(environment.BaseURL + '/_service/um/deleteUser' + "?id=" + id);
    }
    AddNewTenant(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/save/auth/vault/Org', body);
    }
    ChangeProfileImage(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/foldersvc/cloudstorage/upload',body);
      }
    //************* User Manager API Ends */

    //Setting ======>> User Profile API's End
    GetUserProfile(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetUserProfile', body);
    }
    SetUserProfile(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/_service/um/editMyProfile', body);
    }
    ChangePassword(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/_service/um/changeMyPassword', body);
    }
    // RD Dashboard-leadership====> API'S End point
    diabetesClinicalTime(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetRDDashboardLeadershipDCT', body);
    }
    DowloadCSVDashboardLeadershipDCT(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportRDDashboardLeadershipDCT', body);
    }
    GetRDDasshboardLeadershipData(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetRDDasshboardLeadershipData', body);
    }
    ExportRDDasshboardLeadershipData(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportRDDasshboardLeadershipData', body);
    }
    GetAllProviders(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetAllProviders', body);
    }
    GetRDDashboardLeadershipCR(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetRDDashboardLeadershipCR', body);
    }
    GetProviderCancellation(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetProvider', body);
    }
    ExportRDDashboardLeadershipCR(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportRDDashboardLeadershipCR', body);
    }
    GetRDDashboardLeadershipPNS(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetRDDashboardLeadershipPNS', body);
    }
    GetProviderPatient(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetProvider', body);
    }
    ExportRDDashboardLeadershipPNS(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportRDDashboardLeadershipPNS', body);
    }
    GetRDDashboardLeadershipGDMPTable1(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetRDDashboardLeadershipGDMPTable1', body);
    }
    GetRDDashboardLeadershipGDMvsNonGDM(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetRDDashboardLeadershipGDMvsNonGDM', body);
    }
    ExportRDDashboardLeadershipGDMvsNonGDM(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportRDDashboardLeadershipGDMvsNonGDM', body);
    }

     // Simplex Billing Dashboard====> API'S End point
    GetBillingDashboardData(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetBillingDashboardData', body);
    }
    GetBillingDashboardStatus(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetStatus', body);
    }
    GetBillingDashboardServiceType(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetServiceType', body);
    }
    DowloadCSVBillingDashboardEncounter(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportBillingDashboardEncounters', body);
    }
    GetBillingDashboardICTable1(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetBillingDashboardICTable1', body);
    }
    GetBillingDashboardICTable2(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetBillingDashboardICTable2', body);
    }
    GetBillingDashboardICTable3(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetBillingDashboardICTable3', body);
    }
    GetAppointmentType(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetAppointmentType', body);
    }
    GetPrimaryInsurance(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetPrimaryInsurance', body);
    }
    GetChiefComplaint(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetChiefComplaint', body);
    }
    GetClientGroup(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetClientGroup', body);
    }
    GetPayor(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetPayor', body);
    }
    GetClinicalTable1Data(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetClinicalTable1Data', body);
    }
    GetClinicalTable2Data(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetClinicalTable2Data', body);
    }
    DowloadCSVQualityAnalysis1(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportClinicalTable1Data', body);
    }
    DowloadCSVQualityAnalysis2(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportClinicalTable2Data', body);
    }
    ExportBillingDashboardICTable1(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportBillingDashboardICTable1', body);
    }
    ExportBillingDashboardICTable2(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportBillingDashboardICTable2', body);
    }
    ExportBillingDashboardICTable3(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportBillingDashboardICTable3', body);
    }
    ExportBillingDashboardEncounters(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportBillingDashboardEncounters', body);
    }
    ExportTellescopeData(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportTellescopeReport', body);
    }

      // Bliing & ops compliance====> API'S End point
    GetBillingAndOpsComplianceNSR(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetBillingAndOpsComplianceNSR', body);
    }
    ExportBillingAndOpsComplianceNSR(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DownloadCSVGetSBOCNoShow', body);
    }

     // Simplex RD Dashboard====> API'S End point
     GetRDDasshboardData(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetRDDashboardLU', body);
    }
    ExportRDDasshboardData(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DownloadCSVGetRDDashboardLU', body);
    }
    GetRDDashboardCR(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetRDDashboardCR', body);
    }
    ExportRDDashboardCR(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportRDDashboardCR', body);
    }
    GetRDDashboardPNS(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetRDDashboardPNS', body);
    }
    ExportRDDashboardPNS(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportRDDashboardPNS', body);
    }
    GetServiceTypePatient(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetServiceType', body);
    }
    GetRDDashboardGDMPTable1(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetRDDashboardGDMPTable1', body);
    }
    
    GetMetricsComplianceOverview(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetMetricsComplianceOverview', body);
    }

    ExportMetricsComplianceOverview(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportMetricsComplianceOverview', body);
    }

    GetBillingType(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetBillingType', body);
    }

    GetMetricsComplianceWCHW(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetMetricsComplianceWCHW', body);
    }

    GetMetricsComplianceWCLW(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetMetricsComplianceWCLW', body);
    }

    GetMetricsComplianceWCWD(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetMetricsComplianceWCWD', body);
    }

    ExportMetricsComplianceWCHW(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportMetricsComplianceWCHW', body);
    }

    ExportMetricsComplianceWCLW(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportMetricsComplianceWCLW', body);
    }

    ExportMetricsComplianceWCWD(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportMetricsComplianceWCWD', body);
    }
    GetMetricsComplianceA1CTMHL(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetMetricsComplianceA1CTMHL', body);
    }
    GetMetricsComplianceA1CTMT(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetMetricsComplianceA1CTMTrigly', body);
    }
    GetMetricsComplianceA1CTMMSQ(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetMetricsComplianceA1CTMMSQ', body);
    }
    DownloadCSVA1cTriglycerideMSQCombined(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DownloadCSVA1cTriglycerideMSQCombined', body);
    }
    //SubReprots Cholesterol api end points
    GetMetricsComplianceCholesterolHDL(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetMetricsComplianceCholesterolHDL', body);
    }
    GetMetricsComplianceCholesterolLDL(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetMetricsComplianceCholesterolLDL', body);
    }
    GetMetricsComplianceCholesterolTotal(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetMetricsComplianceCholesterolTotal', body);
    }
    DownloadCSVComplianceCholesterolCombined(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/DownloadCSVComplianceCholesterolCombined', body);
    }
    ExportMetricsComplianceCholesterolHDL(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportMetricsComplianceCholesterolHDL', body);
    }
    ExportMetricsComplianceCholesterolLDL(body):Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportMetricsComplianceCholesterolLDL', body);
    }
    ExportMetricsComplianceCholesterolTotal(body):Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportMetricsComplianceCholesterolTotal', body);
    }
    ExportMetricsComplianceA1CTMHL(body):Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportMetricsComplianceA1CTMHL', body);
    }
    ExportMetricsComplianceA1CTMTrigly(body):Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportMetricsComplianceA1CTMTrigly', body);
    }
    ExportMetricsComplianceA1CTMMSQ(body):Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportMetricsComplianceA1CTMMSQ', body);
    }
    TelescopeGetAccountOwner(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/TelescopeGetAccountOwner', body);
    }

    TelescopeGetPatientType(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/TelescopeGetPatientType', body);
    }

    TelescopeGetUPA(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/TelescopeGetUPA', body);
    }

    TelescopeGetTotalConvertedLeads(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/TelescopeGetTotalConvertedLeads', body);
    }

    TelescopeGetTotalConvertedLeadsGraph(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/TelescopeGetTotalConvertedLeadsGraph', body);
    }

    TelescopeExportTotalConvertedLeads(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/TelescopeExportTotalConvertedLeads', body);
    }

    TelescopeGetTotalLeads(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/TelescopeGetTotalLeads', body);
    }

    TelescopeExportTotalLeads(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/TelescopeExportTotalLeads', body);
    }

    TelescopeGetTotalLeadsGraph(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/TelescopeGetTotalLeadsGraph', body);
    }

    TelescopeExportTotalConvertedLeadsByPatientType(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/TelescopeExportTotalConvertedLeadsByPatientType', body);
    }

    TelescopeGetTotalConvertedLeadsByPatientType(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/TelescopeGetTotalConvertedLeadsByPatientType', body);
    }

    TelescopeGetTotalConvertedLeadsByPatientTypeGraph(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/TelescopeGetTotalConvertedLeadsByPatientTypeGraph', body);
    }

    TelescopeGetNewLeadsByPatientTypeGraph(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/TelescopeGetNewLeadsByPatientTypeGraph', body);
    }

    TelescopeGetNewLeadsByPatientType(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/TelescopeGetNewLeadsByPatientType', body);
    }

    TelescopeExportNewLeadsByPatientType(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/TelescopeExportNewLeadsByPatientType', body);
    }

    TelescopeGetTotalNotConvertedLeadsByStatus(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/TelescopeGetTotalNotConvertedLeadsByStatus', body);
    }

    TelescopeGetTotalNotConvertedLeadsByStatusGraph(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/TelescopeGetTotalNotConvertedLeadsByStatusGraph', body);
    }

    TelescopeExportTotalNotConvertedLeadsByStatus(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/TelescopeExportTotalNotConvertedLeadsByStatus', body);
    }

    TelescopeGetNewLeadsByUPA(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/TelescopeGetNewLeadsByUPA', body);
    }

    TelescopeExportNewLeadsByUPA(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/TelescopeExportNewLeadsByUPA', body);
    }

    TelescopeGetNewLeadsByUPAGraph(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/TelescopeGetNewLeadsByUPAGraph', body);
    }

    GetRDDashboardDM(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetRDDashboardDM', body);
    }
    ExportRDDashboardDM(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportRDDashboardDM', body);
    }
    GetRDDashboardGDMPTable2(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetRDDashboardGDMPTable2', body);
    }
    GetRDDashboardLeadershipGDMPTable2(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetRDDashboardLeadershipGDMPTable2', body);
    }
    GetNextApptDate(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetNextApptDate', body);
    }
    ExportRDDashboardLeadershipGDMPTable1(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportRDDashboardLeadershipGDMPTable1', body);
    }
    ExportRDDashboardGDMPTable1(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportRDDashboardGDMPTable1', body);
    }
    ExportRDDashboardLeadershipGDMPTable2(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportRDDashboardLeadershipGDMPTable2', body);
    }
    ExportRDDashboardGDMPTable2(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportRDDashboardGDMPTable2', body);
    }

    GetRDDashboardAUOTable1(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetRDDashboardAUOTable1', body);
    }

    GetRDDashboardAUOTable2(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetRDDashboardAUOTable2', body);
    }

    ExportRDDashboardAUOTable1(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportRDDashboardAUOTable1', body);
    }

    ExportRDDashboardAUOTable2(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportRDDashboardAUOTable2', body);
    }

    GetRDDashboardLeadershipAUOTable1(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetRDDashboardLeadershipAUOTable1', body);
    }

    GetRDDashboardLeadershipAUOTable2(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetRDDashboardLeadershipAUOTable2', body);
    }

    ExportRDDashboardLeadershipAUOTable1(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportRDDashboardLeadershipAUOTable1', body);
    }

    ExportRDDashboardLeadershipAUOTable2(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportRDDashboardLeadershipAUOTable2', body);
    }

    GetRDDashboardLeadershipSHLV(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetRDDashboardLeadershipSHLV', body);
    }
    ExportRDDashboardLeadershipSHLV(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportRDDashboardLeadershipSHLV', body);
    }
    GetRDDashboardSHLV(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetRDDashboardSHLV', body);
    } 
    ExportRDDashboardSHLV(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportRDDashboardSHLV', body);
    } 
    GetOperationsAnalysisWUSI(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetOperationsAnalysisWUSI', body);
    }
    ExportOperationsAnalysisWUSI(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportOperationsAnalysisWUSI', body);
    }
    GetOperationsAnalysisRU(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetOperationsAnalysisRU', body);
    }

    GetRDDashboardLeadershipPOTable1(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetRDDashboardLeadershipPOTable1', body);
    } 

    GetRDDashboardLeadershipPOTable2(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetRDDashboardLeadershipPOTable2', body);
    } 

    GetRDDashboardLeadershipPOTable3(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetRDDashboardLeadershipPOTable3', body);
    } 

    GetRDDashboardLeadershipPOTable4(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetRDDashboardLeadershipPOTable4', body);
    }

    ExportRDDashboardLeadershipPOTable3(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportRDDashboardLeadershipPOTable3', body);
    }

    GetRDDashboardPOTable1(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetRDDashboardPOTable1', body);
    }

    GetRDDashboardPOTable2(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetRDDashboardPOTable2', body);
    }

    GetRDDashboardPOTable3(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetRDDashboardPOTable3', body);
    }

    ExportRDDashboardPOTable3(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportRDDashboardPOTable3', body);
    }

    GetRDDashboardPOTable4(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetRDDashboardPOTable4', body);
    }
    ExportOperationsAnalysisRU(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportOperationsAnalysisRU', body);
    }
    TelescopeGetInsurancePayer(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/TelescopeGetInsurancePayer', body);
    }
    GetOperationsAnalysisClientGroup(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetOperationsAnalysisClientGroup', body);
    }
    GetContactType(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetContactType', body);
    }
    GetOperationsAnalysisAUOTable1(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetOperationsAnalysisAUOTable1', body);
    }
    GetOperationsAnalysisAUOTable2(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetOperationsAnalysisAUOTable2', body);
    }
    ExportOperationsAnalysisAUOTable1(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportOperationsAnalysisAUOTable1', body);
    }
    ExportOperationsAnalysisAUOTable2(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportOperationsAnalysisAUOTable2', body);
    }

    GetOperationsAnalysisWORD(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetOperationsAnalysisWORD', body);
    }

    ExportOperationsAnalysisWORD(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportOperationsAnalysisWORD', body);
    }

    GetOperationsAnalysisAUOServiveTypes(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetOperationsAnalysisAUOServiveTypes', body);
    }

    GetOperationsAnalysisServiceTypes(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetOperationsAnalysisServiceTypes', body);
    }

    GetOperationsAnalysisProviders(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetOperationsAnalysisProviders', body);
    }

    GetOperationsAnalysisABSTable2(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetOperationsAnalysisABSTable2', body);
    }

    ExportOperationsAnalysisABSTable2(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/ExportOperationsAnalysisABSTable2', body);
    }

    GetOperationsAnalysisABSUnits(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetOperationsAnalysisABSUnits', body);
    }

    GetOperationsAnalysisABSAppts(body): Observable<any> {
        return this.http.post<any>(environment.BaseURL + '/ds/function/shared/GetOperationsAnalysisABSAppts', body);
    }
 
}
