import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Simplex Health';
  constructor() {

  }
  ngOnInit(): void {
    let title = document.getElementById('app-title');
    title.textContent = environment.APP_NAME
    
  }
}
