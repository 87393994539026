import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BsDaterangepickerDirective } from 'ngx-bootstrap/datepicker';

import * as dayjs from 'dayjs';

@Component({
  selector: 'app-range-calender',
  templateUrl: './range-calender.component.html',
  styleUrls: ['./range-calender.component.scss']
})
export class RangeCalenderComponent implements OnInit {
  @ViewChild(BsDaterangepickerDirective) daterangepicker: BsDaterangepickerDirective;
  @Output() onDateChange = new EventEmitter<any>();
  @Input() defaultStartDate: dayjs.Dayjs;
  @Input() defaultEndDate: dayjs.Dayjs;
  selectedRangeCalendarAutoLeft: any;
  selectedRangeCalendarCenter: any;
  invalidDates: dayjs.Dayjs[] = [];
  formattedStartDate = 'Start Date';
  formattedEndDate = 'End Date';
  dateRange: Date[];
  selectedDateRange: string;
  isDatePickerOpen = false;
  dropsDown = 'down';
  dropsUp = 'up';
  opensRight = 'right';
  opensCenter = 'center';
  opensLeft = 'left';
  advancedRangeSelected: boolean = false;
  locale = {
    firstDay: 1,
    format: 'MMM DD, YYYY',
    applyLabel: 'Apply',
    cancelLabel: 'Cancel',
    fromLabel: 'From',
    toLabel: 'To',
  };
  ranges: any = {
    Today: [dayjs().startOf('day'), dayjs().endOf('day')],
    Yesterday: [
      dayjs().startOf('day').subtract(1, 'day'),
      dayjs().endOf('day').subtract(1, 'day'),
    ],
    'Last 7 days': [
      dayjs().startOf('day').subtract(6, 'days'),
      dayjs().endOf('day'),
    ],
    'Last 30 days': [
      dayjs().startOf('day').subtract(29, 'days'),
      dayjs().endOf('day'),
    ],
    'This month': [dayjs().startOf('month'), dayjs().endOf('month')],
    'Last month': [
      dayjs().startOf('month').subtract(1, 'month'),
      dayjs().endOf('month').subtract(1, 'month'),
    ],
  };
  tooltips = [
    { date: dayjs(), text: 'Today is just unselectable' },
    { date: dayjs().add(2, 'days'), text: 'Yeeeees!!!' },
  ];
maxDate: dayjs.Dayjs;
  ngOnInit(): void {
    const startDate = this.defaultStartDate || dayjs().set('year', dayjs().year()).set('month', 0).set('date', 1).startOf('day');
    const endDate = this.defaultEndDate || dayjs().endOf('day');

    this.selectedRangeCalendarAutoLeft = {
      startDate,
      endDate,
    };

    this.selectedRangeCalendarCenter = {
      startDate,
      endDate,
    };
    this.formattedStartDate = this.selectedRangeCalendarAutoLeft.startDate.format('MMM DD, YYYY');
    this.formattedEndDate = this.selectedRangeCalendarAutoLeft.endDate.format('MMM DD, YYYY');

    this.maxDate = dayjs().endOf('day'); 
    
  }
  
  isTooltipDate = (m: dayjs.Dayjs) => {
    const tooltip = this.tooltips.find((tt) => tt.date.isSame(m, 'day'));
    return tooltip ? tooltip.text : false;
  };
  isInvalidDate = (m: dayjs.Dayjs) => {
    return this.invalidDates.some((d) => d.isSame(m, 'day'));
  };

  isCustomDate = (date: dayjs.Dayjs) => {
    return date.month() === 0 || date.month() === 6 ? 'mycustomdate' : false;
  };

  datesUpdatedRange($event: any) {

    const startDate = new Date($event.startDate);
    const endDate = new Date($event.endDate);

    const formatDate = (date: Date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
      const day = String(date.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    };

    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);
    let incommingDate = {
      startDate: formattedStartDate,
      endDate: formattedEndDate
    }
    this.onDateChange.emit(incommingDate);
  }
}
