<app-alerts *ngIf="displayErrorBlock" [resMessage]="resMessage"></app-alerts>

<nav class="navbar navbar-expand-lg bg-body-tertiary">
  <div class="container-fluid px-3">
    <div class="d-flex justify-content-start logo-text">
          <div
          *ngIf="userDetails.role != 'user'"
          class="logo mt-1 cursor-pointer"
          (click)="toggleCollapse()"
        >
          <i class="fa-solid fa-bars ms-1 mt-2" style="color: #fff;"></i>
        </div>
          <!-- <span class="font-20 ms-3 cursor-pointer" (click)="goToDashboard()">Simplex Health</span> -->
          <div class="text-center ms-3 cursor-pointer ">
            <img class="" src="../../../../assets/images/simplex-logo.png" (click)="goToDashboard()" height="40" alt="" />
  
          </div>
          
    </div>
    <div class="d-flex justify-content-end" role="search">
      <div ngbDropdown class="d-inline-block me-3">
        <div ngbDropdownToggle class="profile-icon cursor-pointer">
          <img
            (error)="loadAvatar()"
            [src]="imageUrl"
            width="30px"
            height="30px"
            class="object-fit-cover"
          />
        </div>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <div class="header-user-role border-bottom">
            <button
              class="d-flex align-items-center align-item-center no-hover py-2 cursor-none"
              ngbDropdownItem
            >
              <div class="">
                <div class="profile-img position-relative">
                  <div class="justify-content-center">
                    <img
                      class="profile-card object-fit-cover"
                      (error)="loadAvatar()"
                      [src]="imageUrl"
                      alt="Profile Image"
                      width="34px"
                      height="34px"
                    />
                  </div>
                </div>
              </div>
              <div class="ms-3 ">
                <span>
                  {{
                    userDetails?.firstName &&
                    userDetails?.lastName &&
                    userDetails?.firstName.length +
                      userDetails?.lastName.length <=
                      15
                      ? userDetails?.firstName + " " + userDetails?.lastName
                      : (userDetails.userId | titlecase)
                  }}
                </span>
                <p class="mb-0 font-12 text-muted">
                  {{ userDetails?.role | titlecase }}
                </p>
              </div>
            </button>
          </div>

      <div class="border-bottom py-1">
        <button
          class="pl-35  mb-2"
          ngbDropdownItem
          (click)="openUpdateProfileModal(updateProfile)"
        >
        <i class="fa-solid fa-user"></i>
          My Profile
        </button>
            <button
              class="pl-35 "
              ngbDropdownItem
              (click)="openChangePasswordModal(changePassword)"
            >
              <i class="fa-solid fa-key profile-dropdown-icons"></i>
              Change Password
            </button>
          </div> 
          <button class="pl-35 pt-2 " ngbDropdownItem (click)="logout()">
            <i
              class="fa-solid mb-2 fa-arrow-right-from-bracket profile-dropdown-icons"
            ></i>
            Logout
          </button>
        </div>
      </div>
    </div>
  </div>
</nav>

<ng-template #updateProfile let-modal>
  <app-loader *ngIf="loader"></app-loader>
  <div class="modal-header">
    <h4 class="modal-title modal-header-font">My Profile</h4>
  </div>
  <div class="modal-body">
    <div class="form-row">
      <!-- <div class="row">
        <div class="col-md-12 d-flex justify-content-center">
          <div class="profile-avatar"> 
            <img class="separate-width-height" [src]="imageUrl" (error)="loadAvatar()" alt="profile">
            <span class="edit-profile-img d-flex align-items-center justify-content-center
            ">
              <div class="upload-btn-wrapper cursor-pointer">
                <input class="d-none input" id="uploadfile" accept=".png, .jpg, .jpeg" type="file" name="myfile" (change)="changeProfileImage($event)" />
                <label class="cursor-pointer" for="uploadfile"><i class="fa-solid fa-pen" ></i></label>
                </div>
            </span>
          </div>
        </div>
      </div> -->
      <form [formGroup]="updateProfileForm">
        <label class="form-label">First Name</label>
        <div class="form-group col-md-12">
          <input
            type="text"
            class="form-control"
            placeholder="First Name"
            formControlName="firstName"
          />
        </div>
        <div>
          <small
            *ngIf="updateProfileForm.controls['firstName'].hasError('pattern')"
            class="text-danger"
            >First character cannot be space
          </small>
          <small
            *ngIf="
              (!updateProfileForm.controls['firstName'].hasError('required') ||
                updateProfileForm.controls['firstName'].touched) &&
              !updateProfileForm.controls['firstName'].hasError('pattern') &&
              (updateProfileForm.get('firstName')?.value?.length <= 1 ||
                updateProfileForm.get('firstName')?.value?.length >= 33)
            "
            class="text-danger font-14"
          >
            First Name must be between 2-32 characters.</small
          >
        </div>
        <label class="form-label mt-3">Last Name</label>
        <div class="form-group col-md-12">
          <input
            type="text"
            class="form-control"
            placeholder="Last Name"
            formControlName="lastName"
          />
        </div>
        <div>
          <small
            *ngIf="updateProfileForm.controls['lastName'].hasError('pattern')"
            class="text-danger"
            >First character cannot be space
          </small>
          <small
            *ngIf="
              (!updateProfileForm.controls['lastName'].hasError('required') ||
                updateProfileForm.controls['lastName'].touched) &&
              !updateProfileForm.controls['lastName'].hasError('pattern') &&
              (updateProfileForm.get('lastName')?.value?.length <= 1 ||
                updateProfileForm.get('lastName')?.value?.length >= 33)
            "
            class="text-danger font-14"
          >
            Last Name must be between 2-32 characters.</small
          >
        </div>
      </form>
    </div>
  </div>

  <div class="mb-3 px-3">
    <div class="d-flex justify-content-end align-items-center">
      <button
      type="button"
      class="btn btn-outline-secondary me-3"
      (click)="dismissModal()"
      >
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="submitProfileSettingData()"
    >
      Submit
    </button>
    </div>
  </div>
</ng-template>

<ng-template #changePassword let-modal>
  <app-loader *ngIf="loader"></app-loader>
  <div class="modal-header">
    <h4 class="modal-title modal-header-font">Change Password</h4>
  </div>
  <div class="modal-body">
    <div class="form-row">
      <label class="form-label" for="Userid">User Id</label>
      <form [formGroup]="resetPasswordForm">
        <div class="form-group col-md-12 mb-2">
          <input
            type="text"
            class="form-control readOnly"
            id="Userid"
            placeholder="User Id"
            formControlName="userId"
            readonly
          />
        </div>
        <label class="form-label" for="Currentpassword">Current password</label>
        <div class="form-group col-md-12">
          <input
            type="password"
            class="form-control"
            id="Currentpassword"
            placeholder="Current password"
            formControlName="oldPassword"
          />
        </div>
        <div class="mb-2">
          <small
            *ngIf="
              resetPasswordForm.controls['oldPassword'].hasError('required') &&
              resetPasswordForm.controls['oldPassword'].touched
            "
            class="text-danger"
            >Current password is required</small
          >
        </div>
        <label class="form-label" for="Newpassword">New Password</label>
        <div class="form-group col-md-12">
          <input
            type="password"
            class="form-control"
            id="Newpassword"
            placeholder="New Password"
            formControlName="password"
          />
        </div>
        <div class="mb-2">
          <small
            *ngIf="
              resetPasswordForm.controls['password'].hasError('required') &&
              resetPasswordForm.controls['password'].touched
            "
            class="text-danger"
            >New password is required</small
          >
          <small
            *ngIf="
              resetPasswordForm.controls['password'].hasError('pattern') &&
              resetPasswordForm.controls['password'].touched
            "
            class="text-danger"
            >Password needs to be at least 8 characters long.</small
          >
          <small
            *ngIf="
              resetPasswordForm.get('password').hasError('maxlength') &&
              resetPasswordForm.get('password').touched
            "
            class="text-danger"
          >
            Password cannot exceed 24 characters.</small
          >
        </div>
        <label class="form-label" for="Repeatpassword"
          >Confirm New Password</label
        >
        <div class="form-group col-md-12">
          <input
            type="password"
            class="form-control"
            id="Repeatpassword"
            placeholder="Confirm New Password"
            formControlName="rptPassword"
          />
        </div>
        <div class="mb-3">
          <small
            *ngIf="
              resetPasswordForm.controls['rptPassword'].hasError('required') &&
              resetPasswordForm.controls['rptPassword'].touched
            "
            class="text-danger"
            >Confirm new password is required</small
          >
          <small
            *ngIf="resetPasswordForm.controls['rptPassword'].errors?.['mustMatch'] && resetPasswordForm.controls['rptPassword'].dirty"
            class="text-danger"
            >Password and confirm new password not matched</small
          >
        </div>
      </form>
    </div>
  </div>

  <div class="mb-3 px-3">
    <div class="d-flex justify-content-end align-items-center">
      <button
      type="button"
      class="btn btn-outline-secondary me-3"
      (click)="dismissModal()"
      >
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="resetPassword()"
    >
      Submit
    </button>
    </div>
  </div>
</ng-template>
